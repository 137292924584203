.press {
  &-return {
    text-transform: uppercase;
    font-size: 70%;
    font-weight: 700;
    display: block !important; 
    margin-bottom: 1rem;
    font-family: $nav;
    &:hover {
      background: none !important;
    }
  }
  &-meta {
    text-transform: uppercase;
    font-size: 70%;
    font-weight: 700;
    color: $gray;
    display: block;  
  }
		&-immediate,
		&-date {
		  font-weight: bold;
		  text-transform: uppercase;
		  font-size: 90%;
		}
		&-contact {
		  display: block;
		  font-size: 80%;
 			margin: .25rem 0 2rem;
 			color: $gray;
		}
  &-headline {
    font-size: 2.8rem;
    line-height: 1.35;
    padding-bottom: .5rem;
    margin-bottom: 2rem !important;
  		@extend %headline2;	
	 }
  &-footer {
    font-size: 80%;
    color: $gray;
    font-style: italic;
    margin: 2rem 0;
    display: block;
    border-top: 1px $lightgray solid;
    padding: 1rem 0 0 0;
  }
}

.event {
  &-headline {
    font-size: 2.8rem;
    line-height: 1.35;
    padding-bottom: .5rem;
    margin-bottom: 2rem !important;
  		@extend %headline2;	
	 }
		&-date {
		  text-transform: uppercase;
		  font-size: 90%;
		  & p {
		    margin: 0 0 .2rem 0;
		  }
		  &-start,
		  &-end {
		  }
		}
		&-location {
		  display: block;
		  font-size: 80%;
		  font-style: italic;
 			margin: .25rem 0 1rem;
 			color: $darkgray;
		}
}
