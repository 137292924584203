.legislation-form {
  padding: 0;
  background: $lightestgray;
  &-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
    & h3 {
      font-family: $headline2;
    }
    &-cols {
      border: 1px #ddd solid;
      background: white;
      padding: .5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      &.col {
        font-size: 1rem;
        border: none;
        width: 100%;
        padding: 1rem .5rem;
        border: 1px $lightgray solid;
        @include desktop {
          padding: .5rem;
          border:  none;
        }
        &.col1 {
          @include desktop {
            width: 20%;
          }
          & a {
            font-weight: 700;
          }
        }
        &.col2 {
          @include desktop {
            width: 30%;
          }
        }
        &.col3 {
          @include desktop {
            width: 50%;
          }
          & .checkboxes {
            font-weight: 700;
          }
          & .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;  
            margin-bottom: .5rem;
            & .textarea {
              width: 65%;
              padding-right: .5rem;
              & input {
                padding: .25rem;
                border: 1px $gray solid;
                font-size: 1rem;
                width: 100%;
              }
            }
            & .years {
              width: 35%;
              font-size: 1rem;
            }
          }
          & input.button {
            padding: .5rem 1rem;
            border: none;
          }
        }
        & .cat-title {
          display: block;
          font-weight: 700;
        }
        & a:hover {
          background: none;
        }
      }
    }
  }
  &-body {
    padding: 1.5rem;
    & iframe {
      background: white;
      border: 1px #ddd solid;
    }
  }
}

.legislation-form-body iframe#ctiframe div#CTMatrix {
 
}
.legislation-form-body iframe#ctiframe div#CTMatrix .sectionheader {
    background-color: #85022b !important;
    color: #fff !important;
    padding: 5px !important;
}

.legislation-form-body iframe#ctiframe div#CTMatrix tr:nth-child(even) {
    background: #f7f7f7 !important;
}

.legislation-form-body iframe#ctiframe div#CTMatrix td {
    padding: 5px !important;
}

.legislation-form-body iframe#ctiframe div#CTMatrix a.ctpmlink {
    color: #d3001b !important;
    font-weight: bold;
}
