.folder-grid {
   margin: 2em 0;	
   &-item {
      display: block;
      margin: 0 0 .25rem;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
         opacity: 1.0;
         transform: translateY(0);
      }
      &:last-child {
         margin: 0;
      }
   }
   &-aspect {
      display: block !important;
      padding: .75em 1em;
      position: relative;
      top: -.5em;
      left: -.5em;
      text-decoration: none !important;
      background: $lightestgray !important;
      &:hover {
         background: $lightgray !important;
         transform: translateX(-1rem);
         &::before {
           background: $brightred; 
         }
      }
      &:hover .folder-grid-headline {
         color: $brightred !important;
      }
      &:hover .folder-grid-headline::after {
         color: $brightred !important;
      }
      &::before {
         content: "";
         width: 8px;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 0;
         background: $yellow; 
         transition: $slow;
      }	
   }
   &-headline {
      display: block;
      font-family: $headline2 !important;
      color: $red !important;
      margin-bottom: 0 !important;
      font-size: 1.8rem !important;
      &::after {
        content: " \00BB";
        font-family: $nav;
        font-weight: 400;
        color: $red;
        font-size: 2rem;
        line-height: 1;
        position: relative;
        bottom: -1px;
        padding-left: 0.25rem;
      }
   }
   &-description {
      display: block;
      color: $text;
      font-size: 1rem;
   }
   &.horizontal {
      margin: 0 0 2em 0;
      .folder-grid {
         &-item { 
            display: inline-block;
            margin: 0 .5rem;
            opacity: 1.0;
            transform: translateY(0);
            &::after {
               content: "";
               width: 2px;
               height: 100%;
               top: .15rem;
               right: -.75rem;
               background: $gray;
               position: absolute;
               z-index: -10;
            }
            &:first-child {
               margin: 0 .5rem 0 0;
            }
            &:last-child {
               margin: 0 0 0 .5rem;
               &::after {
                  display: none;
               }
            }
         }
         &-aspect {
            display: inline-block !important;
            padding: 0;
            position: relative;
            top: initial;
            left: initial;
            text-transform: uppercase;
            text-decoration: none !important;
            background: $clear !important;
            &::before {
               display: none;
            }   
            &:hover {
               background: $clear !important;
               &::after {
                  content: "";
                  width: calc(100% + .5rem);
                  height: 4px;
                  position: absolute;
                  bottom: -.25rem;
                  left: -.25rem;
                  z-index: -1;
                  background: $yellow; 
               }
            }
            &:hover .folder-grid-headline {
               color: $black !important;
            } 
         }
         &-headline {
            display: inline-block;
            font-family: $nav !important;
            color: $darkgray !important;
            margin-bottom: 0 !important;
            font-size: 1.0rem !important;
         }
         &-description {
            display: none;
         }
      }
   }
}
